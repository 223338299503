import { useSpring, animated, config } from 'react-spring'
import { useHasMouse } from '/machinery/HasMouse'

import styles from './FollowCursor.css'

export const FollowCursor = React.forwardRef(FollowCursorImpl)
function FollowCursorImpl({
  children,
  Cursor,
  onCursorMove = undefined,
  onMouseLeave = undefined,
  onMouseEnter = undefined,
}, ref) {
  const componentRef = React.useRef(null)
  const cursorRef = React.useRef(null)

  const [{ x, y }, setCursorPosition] = React.useState({ x: 0, y: 0 })
  const [isFollowing, setIsFollowing] = React.useState(false)

  useDefaultMousePosition({ ref, cursorRef, onChange: setCursorPosition })

  const { handleMouseMove, handleMouseLeave, handleMouseEnter } = useFollowCursorHandlers({
    componentRef,
    setCursorPosition,
    setIsFollowing,
    onCursorMove,
    onMouseLeave,
    onMouseEnter,
    ref,
    cursorRef,
    x,
    y,
  })

  const style = useSpring({
    config: isFollowing ? { tension: 250, friction: 18 } : config.slow,
    transform: `translate(${x}px, ${y}px)`
  })

  return (
    <div
      ref={componentRef}
      className={styles.componentImpl}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={isFollowing ? handleMouseMove : undefined}
    >
      {children}
      <CursorPosition ref={cursorRef} {...{ style }} layoutClassName={styles.cursorPositionLayout}>
        <div className={styles.cursor}>
          <Cursor {...{ isFollowing, x, y }} />
        </div>
      </CursorPosition>
    </div>
  )
}

const CursorPosition = React.forwardRef(CursorPositionImpl)
function CursorPositionImpl({ style, layoutClassName, children }, ref) {
  return (
    <animated.div
      {...{ ref }}
      className={cx(styles.componentCursorPositionImpl, layoutClassName)}
      {... { style }}
    >
      {children}
    </animated.div>
  )
}

export function FollowCursorCursor({ children, isFollowing, IconContainer, layoutClassName = undefined }) {
  return (
    <IconContainer isActive={isFollowing} {...{ layoutClassName }}>
      {children}
    </IconContainer>
  )
}

function useDefaultMousePosition({ ref, cursorRef, onChange }) {
  const initializedRef = React.useRef(null)

  React.useEffect(() => {
    if (!ref?.current || !cursorRef?.current) return
    if (!initializedRef.current) {
      initializedRef.current = true
      return
    }
    const { x, y } = getPositionValues({ ref, cursorRef })
    onChange({ x, y })
  }, [ref?.current, cursorRef?.current])

  return null
}

function getPositionValues({ ref, cursorRef }) {
  const { left, width, height, top } = ref?.current.getBoundingClientRect()
  const { width: cursorWidth, height: cursorHeight } = cursorRef.current.getBoundingClientRect()
  const x = left + width - (left + width / 2) - (cursorWidth / 2)
  const y = top + height - (top + height / 2) - (cursorHeight / 2)

  return { x, y }
}

function useFollowCursorHandlers({ componentRef, setCursorPosition, setIsFollowing, onCursorMove, onMouseLeave, onMouseEnter, x, y, ref, cursorRef }) {
  const { hasMouse } = useHasMouse()

  const handleMouseMove = React.useCallback(({ clientX, clientY }) => {
    if (!hasMouse) return
    const { top, left, width, height } = componentRef.current.getBoundingClientRect()
    setCursorPosition({
      x: clientX - left - width / 2,
      y: clientY - top - height / 2,
    })
  }, [hasMouse, componentRef, setCursorPosition])

  const handleMouseLeave = React.useCallback(() => {
    setIsFollowing(false)
    if (ref?.current && cursorRef?.current) {
      const { x, y } = getPositionValues({ ref, cursorRef })
      setCursorPosition({ x, y })
    } else {
      setCursorPosition({ x: 0, y: 0 })
    }

    onMouseLeave?.()
  }, [setIsFollowing, setCursorPosition, onMouseLeave, ref, cursorRef])

  const handleMouseEnter = React.useCallback(() => {
    setIsFollowing(true)
    onMouseEnter?.()
  }, [setIsFollowing, onMouseEnter])

  React.useEffect(() => {
    if (onCursorMove) onCursorMove({ x, y })
  }, [x, y, onCursorMove])

  return { handleMouseMove, handleMouseLeave, handleMouseEnter }
}
