import { ContainerDark, ContainerLight } from '/components/home/Container'
import { LinkButtonWhite, LinkTextArrow } from '/components/buildingBlocks/Link'
import { useSanityClient } from '/machinery/SanityClient'
import { Image } from '/components/Image'
import styles from './Hero.css'

export function Hero({ title, image }) {
  return (
    <div className={styles.component}>
      <PrimaryLayer {...{ title, image }} />
      {/* //TODO: Create second layer */}
    </div>
  )
}

function PrimaryLayer({ title, image }) {
  const { imageBuilder } = useSanityClient()

  return (
    <ContainerDark>
      <div className={styles.componentPrimaryLayer}>
        <div className={styles.layoutPrimaryLayer}>
          <Content {...{ title }} />
        </div>
        <div className={styles.coverImage}>
          {image?.asset && (
            <Image fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.imageLayout} />
          )}
        </div>
      </div>
    </ContainerDark>

  )
}

function Content({ title }) {
  return (
    <div className={styles.componentContent}>
      <div className={styles.layoutContent}>
        {title && (
          <h1 className={styles.title}>{title}</h1>
        )}
        <div className={styles.buttons}>
          <LinkButtonWhite
            to='tel:+31302876400'
            datax='link-to-call'
            dataxcontext='hero'
          >
            Bel 030 287 64 00
          </LinkButtonWhite>
          <LinkTextArrow
            to='mailto:hallo@kaliber.net'
            datax='link-to-email'
            dataxcontext='hero'
            target='_blank'
          >
            Stuur ons een mailtje
          </LinkTextArrow>
        </div>
      </div>
    </div>
  )
}
