import styles from './Icon.css'

export function Icon({ icon, layoutClassName = undefined }) {
  return (
    <span
      className={cx(styles.component, layoutClassName)}
      role='presentation'
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  )
}

export function IconPlay() {
  return <Svg size={20}><path fill="currentColor" d="M2 0l15.607 10.263L2 20.526z" /></Svg>
}

export function IconPause() {
  return <Svg size={20}><path d="M3 0h4v20H3zM13 0h4v20h-4z" /></Svg>
}

export function IconLeftArrow() {
  return (
    <svg
      viewBox='0 0 73 73'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path fill="currentColor" d="M18.5688674,0 L30.6630888,12.8272045 L18.5688674,25.6544091 L15.6585097,22.9103576 L23.278,14.827 L0,14.8272045 L0,10.8272045 L23.278,10.827 L15.6585097,2.74405147 L18.5688674,0 Z" transform="matrix(-1 0 0 1 50.914 24.173)" />
    </svg>
  )
}

export function IconRightArrow() {
  return (
    <svg
      viewBox='0 0 73 73'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path fill="currentColor" d="M18.5688674,0 L30.6630888,12.8272045 L18.5688674,25.6544091 L15.6585097,22.9103576 L23.278,14.827 L0,14.8272045 L0,10.8272045 L23.278,10.827 L15.6585097,2.74405147 L18.5688674,0 Z" transform="matrix(1 0 0 -1 20.251 49.827)" />
    </svg>
  )
}

export function IconCross() {
  return (
    <Svg size={36}>
      <path d="M35.633 5.81 23.568 17.875 35.633 29.94l-5.568 5.568L18 23.443 5.935 35.508.367 29.94l12.065-12.065L.367 5.81 5.935.242 18 12.307 30.065.242z" />
    </Svg>
  )
}

export function IconMenu() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="20" viewBox="0 0 32 20">
      <path d="M0,0 L32,0 L32,6 L0,6 L0,0 Z M0,14 L32,14 L32,20 L0,20 L0,14 Z" />
    </svg>
  )
}

export function IconPlus() {
  return (
    <svg width="32" viewBox="0 0 32 32" height="32" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fillRule="evenodd">
        <path d="M14 9h3v14h-3z" fill='#fff' />
        <path d="M22.5 14.5v3h-14v-3z" fill='#fff' />
      </g>
    </svg>
  )
}

export function IconHandLeft() {
  return (
    <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 190 146">
      <path fill="#000" fillRule="evenodd" d="M76.082 15.41V7.547h9.434V0h28.696v27.91h-8.023v6.68h77.832v7.395H190v20.987h-6.137v6.294h-35.454v19.019h-9.04v23.906h-8.962v17.139h-6.765v9.281h-8.491v7.076H50.607v-7.704h-25v-9.907H.287V41.354h25.162v-8.648h17.14v-8.334h16.825v-8.963h16.668Z" clipRule="evenodd" />
      <path fill="#fff" fillRule="evenodd" d="M81.188 18.007v7.861H63.733v8.963h-16.51v8.333h-17.14v8.649H10.747v65.803h16.036v9.906H53.67v7.704h55.432v-7.075h10.846v-7.152H94.793V110.54h34.116v-14.7h-25.312V85.38h34.352V69.266h-32.152v-10.46h73.744V45.05H77.965V34.59H95.73V23.587h8.023V10.459H90.78v7.548h-9.592Z" clipRule="evenodd" />
    </svg>
  )
}

export function IconHandDiagonal() {
  return (
    <svg width="281" height="263" viewBox="0 0 281 263" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_76_667)">
        <path fillRule="evenodd" clipRule="evenodd" d="M126.022 201.421L121.372 209.475L111.708 203.895L107.244 211.627L77.849 194.655L94.3553 166.066L102.574 170.811L106.525 163.968L26.797 117.937L31.1702 110.362L25.0455 106.826L37.4578 85.3273L43.7438 88.9566L47.4659 82.5098L83.7841 103.478L95.0325 83.9954L104.293 89.3417L118.431 64.8537L127.611 70.1538L137.747 52.5965L144.678 56.5978L150.167 47.0906L158.864 52.1122L163.049 44.8643L229.165 83.0366L224.609 90.9284L250.219 105.714L244.36 115.862L270.297 130.837L219.008 219.672L193.232 204.79L188.118 213.649L170.561 203.513L165.632 212.049L148.396 202.098L143.096 211.279L126.022 201.421Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M122.328 195.741L126.977 187.687L144.858 198.011L150.158 188.83L167.071 198.594L172 190.058L189.556 200.194L194.671 191.335L214.479 202.771L253.396 135.366L236.97 125.882L242.829 115.734L215.286 99.8316L219.842 91.94L163.06 59.1569L158.875 66.4048L147.765 59.9901L143.535 67.3159L169.303 82.193L163.117 92.9075L128.169 72.7304L119.476 87.7877L145.405 102.758L139.219 113.472L104.03 93.1558L94.4987 109.664L127.434 128.68L121.248 139.394L45.7082 95.7813L37.5728 109.872L141.622 169.945L135.436 180.66L117.239 170.154L110.732 181.425L102.513 176.68L94.7495 190.127L108.038 197.799L112.502 190.068L122.328 195.741Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_76_667">
          <rect width="172.323" height="224.738" fill="white" transform="translate(0.215088 149.833) rotate(-60)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export function IconHandRight() {
  return (
    <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 190 146">
      <path fill="#000" fillRule="evenodd" d="M113.918 15.41V7.547h-9.434V0H75.788v27.91h8.023v6.68H5.979v7.395H0v20.987h6.137v6.294H41.59v19.019h9.04v23.906h8.962v17.139h6.765v9.281h8.491v7.076h64.544v-7.704h25.001v-9.907h25.32V41.354h-25.163v-8.648h-17.139v-8.334h-16.826v-8.963h-16.668Z" clipRule="evenodd" />
      <path fill="#fff" fillRule="evenodd" d="M108.812 18.007v7.861h17.455v8.963h16.51v8.333h17.14v8.649h19.337v65.803h-16.036v9.906H136.33v7.704H80.898v-7.075H70.052v-7.152h25.155V110.54H61.091v-14.7h25.312V85.38H52.05V69.266h32.152v-10.46H10.46V45.05h101.575V34.59H94.27V23.587h-8.023V10.459H99.22v7.548h9.592Z" clipRule="evenodd" />
    </svg>
  )
}

function Svg({ children, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns='http://www.w3.org/2000/svg'
    >
      {children}
    </svg>
  )
}
